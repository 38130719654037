import {
  lazy,
} from 'react';
import {
  RouteObject,
} from 'react-router-dom';
import {
  lazyLoadComponent,
} from 'utils/router';

export const chartsRoutes: RouteObject[] = [{
  path: '/charts/:id/:tab',
  element: lazyLoadComponent(
    lazy(() => import('./pages/Details')),
  ),
}, {
  path: '/charts/:id',
  element: lazyLoadComponent(
    lazy(() => import('./pages/Details')),
  ),
}];
