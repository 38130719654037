import keyBy from 'lodash/keyBy';
import {
  MutationFunction,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from 'react-query';
import {
  formatDateTimeOffset,
} from 'utils/date';
import {
  Dayjs,
} from 'utils/dayjs';
import {
  createAppointment,
  createAvailability,
  createBlockedTimeSlot,
  deleteAppointment,
  deleteAvailability,
  deleteBlockedTimeSlot,
  getAppointmentById,
  getAppointmentsDailyBreakdown,
  getBlockedTimeSlotById,
  getCalendarEventsByDateRange,
  getProviderAvailabilitySlotById,
  modifyAppointment,
  modifyAvailability,
  modifyBlockedTimeSlot,
  savePatient,
  sendAppointmentReminder,
  toggleFavorite,
} from 'pages/Dashboard/pages/Appointments/pages/List/services/api';
import {
  CalendarEvent,
  CalendarEventWithPartialOptions,
  CalendarTimelineEvent,
  EventType,
} from 'pages/Dashboard/pages/Appointments/pages/List/types/appointment';
import {
  AppointmentDTO,
  BlockedTimeSlotDTO,
  CHPayerListingDTO,
  CreateAppointmentResponseDTO,
  DeleteAppointmentRequestDTO,
  DeleteCalendarSlotResponseDTO,
  PatientDTO,
  ProblemDetails,
  ProviderAvailabilityDTO,
  ReminderCommuniqueRequestDTO,
} from 'dtos';
import {
  CreatePatientPayload,
  ToggleFavoritePayload,
} from 'pages/Dashboard/pages/Appointments/pages/List/types/patient';
import {
  DATE_UNIT_BY_VIEW_TYPE,
} from 'pages/Dashboard/pages/Appointments/pages/List/utils/helper';
import {
  getColorByName,
} from 'utils/colors';
import {
  useColorsHashMap,
} from 'pages/Dashboard/pages/Encounters/hooks';
import useUserInfo from 'pages/Dashboard/hooks/useUserInfo';

export type CreateAppointmentRequestResponseDataType = BlockedTimeSlotDTO &
CreateAppointmentResponseDTO & ProviderAvailabilityDTO;
export type ModifyAppointmentRequestResponseDataType = BlockedTimeSlotDTO &
 AppointmentDTO & ProviderAvailabilityDTO;
type GeneralCalendarEvent = CalendarEvent | CalendarTimelineEvent;

const cssClassMap: Record<string, string> = {
  'Time Blocked': 'is-time-block-slot',
  Availability: 'is-provider-availability-slot',
};

export function useAppointments(
  date: Dayjs,
  viewType: 'Daily' | 'Weekly' | 'Monthly',
): UseQueryResult<GeneralCalendarEvent[], Error> {
  const colorsHashMap = useColorsHashMap();
  const userInfo = useUserInfo();
  const officeLocationMap = keyBy(userInfo?.organization?.officeLocations ?? [], 'addressId');

  const [start, end] = [
    formatDateTimeOffset(date.startOf(DATE_UNIT_BY_VIEW_TYPE[viewType])),
    formatDateTimeOffset(date.endOf(DATE_UNIT_BY_VIEW_TYPE[viewType])),
  ];

  const isMonthlyView = viewType === 'Monthly';

  return useQuery<GeneralCalendarEvent[], Error>(
    ['appointments', viewType, start, end],
    () => (
      isMonthlyView
        ? getAppointmentsDailyBreakdown(start, end)
        : getCalendarEventsByDateRange(start, end)
    ),
    {
      select: (data: GeneralCalendarEvent[]) => (
        data.map((event) => {
          const officeLocation = isMonthlyView
            ? officeLocationMap[event?.officeLocationId ?? '']
            : event.officeLocation;
          const colorKey = event.isTimeAvailability
            ? officeLocation?.nickName
            : event.typeOfVisitDescription;

          return ({
            ...event,
            cssClass: cssClassMap[event.typeOfVisitDescription ?? ''],
            color: getColorByName((colorsHashMap[colorKey ?? ''] ?? '!bg-emerald-200').replace('!bg-', '')),
            officeLocation,
            typeOfVisitDescription: isMonthlyView && event.isTimeAvailability
              ? officeLocation?.nickName ?? ''
              : event?.typeOfVisitDescription ?? '',
          });
        })
      ),
    },
  );
}

const getEventDetailsFunctionList = Object.freeze({
  appointment: getAppointmentById,
  blockedTimeSlot: getBlockedTimeSlotById,
  providerAvailability: getProviderAvailabilitySlotById,
});

export function useGetAppointmentById(
  id: number,
  isTimeBlock: boolean,
  isAvailability: boolean,
  enabled: boolean = true,
): UseQueryResult<CalendarEvent, Error> {
  const nonBlockTimeKey = isAvailability ? 'providerAvailability' : 'appointment';
  const fnKey = isTimeBlock ? 'blockedTimeSlot' : nonBlockTimeKey;

  return useQuery<any, Error>({
    queryKey: [fnKey, id],
    queryFn: () => getEventDetailsFunctionList[fnKey](id),
    enabled,
    select: (data) => data ?? {},
  });
}

type DeleteMutationResponse = DeleteAppointmentRequestDTO
  & ProblemDetails
  & DeleteCalendarSlotResponseDTO;

const transportFunctionsByType = Object.freeze({
  appointment: {
    create: createAppointment,
    update: modifyAppointment,
    remove: deleteAppointment,
  },
  timeBlock: {
    create: createBlockedTimeSlot,
    update: modifyBlockedTimeSlot,
    remove: deleteBlockedTimeSlot,
  },
  availability: {
    create: createAvailability,
    update: modifyAvailability,
    remove: deleteAvailability,
  },
});

export function useDeleteAppointment():
  UseMutationResult<
    DeleteMutationResponse,
    Error,
    CalendarEventWithPartialOptions> {
  return useMutation<
    DeleteMutationResponse,
    Error,
    CalendarEventWithPartialOptions>({
      mutationFn: ({ data, options }: CalendarEventWithPartialOptions) => {
        const { remove } = transportFunctionsByType[data?.typeName ?? 'appointment'];
        return (remove as MutationFunction<DeleteMutationResponse>)({ data, options });
      },
    });
}

export function useSendAppointmentReminder():
  UseMutationResult<void, Error, ReminderCommuniqueRequestDTO> {
  return useMutation<void, Error, ReminderCommuniqueRequestDTO>(sendAppointmentReminder);
}

export function useModifyAppointment():
  UseMutationResult<ModifyAppointmentRequestResponseDataType, Error, Partial<CalendarEvent>> {
  return useMutation({
    mutationFn: (data) => {
      const { update } = transportFunctionsByType[data?.typeName ?? 'appointment'];
      return (
        update as MutationFunction<ModifyAppointmentRequestResponseDataType>)(
        data,
      );
    },
  });
}

export function useCreateAppointment(eventType: EventType):
UseMutationResult<CreateAppointmentRequestResponseDataType, Error,
 CreateAppointmentRequestResponseDataType> {
  const { create } = transportFunctionsByType[eventType ?? 'appointment'];
  return useMutation<CreateAppointmentRequestResponseDataType,
    Error, CreateAppointmentRequestResponseDataType>(
    create as MutationFunction<
      CreateAppointmentRequestResponseDataType,
      CreateAppointmentRequestResponseDataType>,
    );
}

export function useSavePatient():
  UseMutationResult<PatientDTO, Error, Partial<CreatePatientPayload>> {
  return useMutation<
    PatientDTO, Error, Partial<CreatePatientPayload>>(savePatient);
}

export function useToggleFavorite(): UseMutationResult<
  CHPayerListingDTO, Error, ToggleFavoritePayload> {
  return useMutation<CHPayerListingDTO, Error, ToggleFavoritePayload>(toggleFavorite);
}
