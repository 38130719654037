export enum MediaCategory {
  Xray = 'Xray',
  OtherProviderNote = 'OtherProviderNote',
  InsuranceCard = 'InsuranceCard',
  LabReport = 'LabReport',
  MedicalRecords = 'MedicalRecords',
  VisitNoteImage = 'VisitNoteImage',
  ProfilePicture = 'ProfilePicture',
  FinalizedVisitNote = 'FinalizedVisitNote',
  Identification = 'Identification',
  ConsentForms = 'ConsentForms',
  MedicalNotes = 'MedicalNotes',
  RadiologyImages = 'RadiologyImages',
  EOB = 'EOBDoc',
}

export enum MediaOriginatorType {
  Org = 'Org',
  Patient = 'Patient',
  Billing = 'Billing',
}

export const imagesTypes = [{
  label: 'MRI',
  value: 'MRI',
}, {
  label: 'X - Ray',
  value: 'Xray',
}, {
  label: 'CT Scan',
  value: 'CTScan',
}, {
  label: 'Ultrasound',
  value: 'Ultrasound',
}];

export const reportTypes = [{
  label: 'Lab Report',
  value: 'LabReport',
}, {
  label: 'EMG',
  value: 'EMG',
}, {
  label: 'Pathology',
  value: 'Pathology',
}, {
  label: 'Operative Report',
  value: 'OperativeReport',
}];

export const legalDocsTypes = [{
  label: 'Personal Injury Paperwork',
  value: 'PersonalInjuryPaperwork',
}, {
  label: 'Worker`s Compensation Paperwork',
  value: 'WorkersCompensationPaperwork',
}, {
  label: 'Car accident Paperwork',
  value: 'CarAccidentPaperwork',
}];

export const noteTypes = [{
  label: 'Other provider note',
  value: 'OtherProviderNote',
}, {
  label: 'Consult note',
  value: 'ConsultNote',
}];

export const otherTypes = [{
  label: 'Lab Slip',
  value: 'LabSlip',
}, {
  label: 'Consent',
  value: 'Consent',
}, {
  label: 'Prescription',
  value: 'Prescription',
}, {
  label: 'Bill Slip',
  value: 'BillSlip',
}, {
  label: 'Appeal',
  value: 'Appeal',
}, {
  label: 'Other',
  value: 'Other',
}];
