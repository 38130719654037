/**
 * Generated by orval v6.14.3 🍺
 * Do not edit manually.
 * PopulateAPI
 * OpenAPI spec version: v1
 */

export type PatientPaymentTypes = typeof PatientPaymentTypes[keyof typeof PatientPaymentTypes];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatientPaymentTypes = {
  PatientBalance: 'PatientBalance',
  Copay: 'Copay',
} as const;
