import {
  request,
} from 'services/api';
import {
  transformEncounter,
} from 'pages/Dashboard/pages/Encounters/pages/List/utils/helper';
import {
  EncounterDetailsDTO,
} from 'dtos';
import {
  Encounter,
} from 'pages/Dashboard/pages/Encounters/pages/List/types/encounter';

const getEncounters = (date: string): Promise<Encounter[]> => (
  request<EncounterDetailsDTO[]>('Encounter/GetEncountersByDate', {
    method: 'GET',
    params: { date: encodeURI(date) },
  })
    .then((encounters: EncounterDetailsDTO[]) => encounters.map(transformEncounter))
);

export { getEncounters };
