import {
  normalizeFilterableSettings,
} from 'core/Filters/util';
import {
  SettingsDTO,
} from 'dtos/settingsDTO';
import usePrefetchedData from 'pages/Dashboard/hooks/usePrefetchedData';
import {
  getChargesFilterSettings,
  getLookupOptions,
} from 'pages/Dashboard/pages/Billing/api';
import {
  UseQueryResult,
  useQuery,
} from 'react-query';
import {
  isEmptyString,
} from 'utils/misc';

export function useChargesFilterMetaComponents() {
  const { data } = usePrefetchedData<SettingsDTO>({
    key: 'charge-filter-settings',
    fetchFn: getChargesFilterSettings,
  });

  return normalizeFilterableSettings(data?.filterableSettings ?? []);
}

export default function useSearchLookupOptions(
  optionLookupUrl: string,
  searchQuery: string,
  enabled: boolean,
): UseQueryResult<any[], Error> {
  return useQuery<any[], Error>(
    ['filter-lookup', optionLookupUrl, searchQuery],
    () => getLookupOptions(optionLookupUrl, searchQuery),
    { enabled: enabled && !isEmptyString(searchQuery) },
  );
}
