import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';
import {
  region,
  subscriptionKey,
} from 'env';
import {
  useMixpanelTrack,
} from 'core/hooks';

export type VoiceRecognitionData = {
  unprocessedText: string;
  text: string;
  loading: boolean;
  listening: boolean;
  startVoiceRecognition: () => void;
  stopVoiceRecognition: () => void;
};

type SpeechToTextProps = {
  trackingLabel?: string;
};

const speechConfig = sdk.SpeechConfig.fromSubscription(subscriptionKey, region);
speechConfig.enableDictation();
speechConfig.setServiceProperty('punctuation', 'explicit', sdk.ServicePropertyChannel.UriQueryParameter);
const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();

function useSpeechToText({ trackingLabel }: SpeechToTextProps): VoiceRecognitionData {
  const mixpanelTrack = useMixpanelTrack();
  const recognizer = useMemo(() => new sdk.SpeechRecognizer(speechConfig, audioConfig), []);
  const [loading, setLoading] = useState<boolean>(false);
  const [listening, setListening] = useState<boolean>(false);
  const [unprocessedText, setUnprocessedText] = useState<string>('');
  const [text, setText] = useState<string>('');

  useEffect(() => {
    recognizer.recognizing = (s, { result }) => {
      setUnprocessedText(result?.text?.trim());
    };

    recognizer.recognized = (s, { result }) => {
      const { reason, text } = result;
      if (text && reason === sdk.ResultReason.RecognizedSpeech) {
        setText(text);
        setUnprocessedText('');
      } else if (reason === sdk.ResultReason.NoMatch) {
        console.error('Speech could not be recognized.');
      }
    };
  }, [recognizer]);

  const startVoiceRecognition = () => {
    setLoading(true);
    recognizer.startContinuousRecognitionAsync(() => {
      setLoading(false);
      setListening(true);
    }, (err: string) => {
      setLoading(false);
      console.error('Failed to startContinuousRecognitionAsync', err);
    });
    mixpanelTrack('startedDictation', { trackingLabel });
  };

  const stopVoiceRecognition = () => {
    setLoading(true);
    recognizer.stopContinuousRecognitionAsync(() => {
      setLoading(false);
      setListening(false);
      setText('');
      setUnprocessedText('');
    }, (err: string) => {
      setLoading(false);
      console.error('Failed to stopContinuousRecognitionAsync', err);
    });
    mixpanelTrack('stoppedDictation', { trackingLabel });
  };

  return {
    unprocessedText,
    text,
    loading,
    listening,
    startVoiceRecognition,
    stopVoiceRecognition,
  };
}

export default useSpeechToText;
