import {
  ComponentType,
  MouseEvent,
} from 'react';
import {
  RouteObject,
} from 'react-router-dom';
import {
  BillingIcon,
  CalendarIcon,
  FolderIcon,
  HomeIcon,
  MailOpenIcon,
  ReportIcon,
} from 'core/icons';
import {
  appointmentRoutes,
} from 'pages/Dashboard/pages/Appointments/routes';
import {
  billingRoutes,
} from 'pages/Dashboard/pages/Billing/routes';
import {
  chartsRoutes,
} from 'pages/Dashboard/pages/Charts/routes';
import {
  encountersRoutes,
} from 'pages/Dashboard/pages/Encounters/routes';
import NotificationIndicator from 'pages/Dashboard/pages/Notifications/NotificationIndicator';
import {
  LoggedInUserInfoDTO,
} from 'dtos';
import isNil from 'lodash/isNil';
import ToDosSidebarItem from 'pages/Dashboard/components/ToDosSidebarItem';
import {
  faxRoutes,
} from 'pages/Dashboard/pages/Fax/routes';
import {
  settingsRoutes,
} from 'pages/Dashboard/pages/Settings/routes';
import SettingsIcon from 'core/icons/settings';

type Route = {
  name: string;
  title?: string;
  href?: string;
  className?: string;
  Icon?: ComponentType;
  Component?: ComponentType;
  routes?: Route[];
  subRoutes?: RouteObject[];
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  hasFlag?: boolean;
};

export default [{
  Icon: HomeIcon,
  name: 'Encounters',
  href: 'encounters',
  subRoutes: encountersRoutes,
}, {
  Icon: FolderIcon,
  name: 'Charts',
  href: 'charts',
  className: '!hidden',
  subRoutes: chartsRoutes,
}, {
  Icon: CalendarIcon,
  name: 'Appointments',
  href: 'appointments',
  subRoutes: appointmentRoutes,
}, {
  name: 'Todos',
  href: 'todos',
  title: 'To-dos',
  Component: ToDosSidebarItem,
}, {
  Icon: BillingIcon,
  name: 'Billing',
  href: 'billing',
  subRoutes: billingRoutes,
}, {
  Icon: MailOpenIcon,
  name: 'Fax',
  title: 'Fax',
  href: 'fax',
  hasFlag: (userInfo: LoggedInUserInfoDTO) => !isNil(userInfo?.organization?.eFaxConfig),
  subRoutes: faxRoutes,
}, {
  Icon: ReportIcon,
  title: 'Reports',
  name: 'Reports',
  href: 'reports',
}, {
  Icon: SettingsIcon,
  title: 'Settings',
  name: 'Settings',
  href: 'settings',
  className: '!hidden',
  subRoutes: settingsRoutes,
}, {
  Component: NotificationIndicator,
  name: 'Notifications',
  href: 'notifications',
  className: '!hidden',
}] as Route[];
