import {
  ClaimTransmissionDTO,
  FaxContactDTO,
  InsuranceDTO,
  InsurancePaymentDTO,
  MultiMediaDeleteDTO,
  VisitNoteMipsDTO,
} from 'dtos';
import createEventBus from 'core/EventBus';
import {
  Claim,
  Payment,
  PaymentStatus,
} from 'pages/Dashboard/pages/Billing/types';
import {
  Entity,
} from 'pages/Dashboard/contexts/sidePanel';
import {
  InsuranceDialogParams,
} from 'pages/Dashboard/pages/Appointments/pages/List/components/Details/InsuranceDialog';
import {
  AppointmentConfirmationParams,
} from 'pages/Dashboard/pages/Appointments/pages/List/components/AppointmentConfirmation';
import {
  CreateAppointmentDialogParams,
} from 'pages/Dashboard/pages/Appointments/pages/List/components/CreateAppointmentDialog';
import {
  ToDoDialogParams,
} from 'pages/Dashboard/pages/Todos/CreateToDoForm';
import {
  PaymentDialogParams,
} from 'pages/Dashboard/pages/Billing/Payments/PaymentDialog';
import {
  EOBDialogParams,
} from 'pages/Dashboard/pages/Billing/Payments/EOBDialog';
import {
  EOBAdjustmentCodeParams,
} from 'pages/Dashboard/pages/Billing/Payments/EOBDialog/EOBAdjustmentCodePopover';
import {
  ChargePolicyDialogParams,
} from 'pages/Dashboard/pages/Billing/ChargeMaster/ChargePolicyDialog';
import {
  BillingCode,
} from 'pages/Dashboard/pages/Encounters/pages/VisitNote/types/billingCode';
import {
  ViewName as PaymentViewName,
} from 'pages/Dashboard/pages/Billing/Payments';
import {
  ReportDialogParams,
} from 'pages/Dashboard/pages/Reports/ReportDialog';
import {
  PatientCompact,
} from 'pages/Dashboard/pages/Encounters/types/patient';
import {
  PatientDialogParams,
} from 'pages/Dashboard/pages/Appointments/pages/List/components/CreatePatientDialog';
import {
  CreateAppointmentTypeDialogParams,
} from 'pages/Dashboard/pages/Settings/Appointments/AppointmentType/CreateAppointmentTypeDialog';
import {
  SecondaryClaimDialogParams,
} from 'pages/Dashboard/pages/Billing/ClaimForm/SecondaryClaimDialog';

type MediaEventChannel = {
  onMediaDeleted: (mediaPayload: MultiMediaDeleteDTO) => void;
  onMediaUpdated: () => void;
};
export const mediaEventChannel = createEventBus<MediaEventChannel>();

type InsurancesEventChannel = {
  openInsuranceDialog: (params: InsuranceDialogParams) => void;
  onPrimaryInsuranceChange: (checked: boolean, index?: number) => void;
  onInsuranceCorrection: (insurance: InsuranceDTO, patientId: number) => void;
};
export const insurancesEventChannel = createEventBus<InsurancesEventChannel>();

type AppointmentsEventChannel = {
  onAppointmentConfirmation: (params: AppointmentConfirmationParams) => void;
  onAppointmentInsuranceChange: (
    insurance: InsuranceDTO,
    encounterId?: number,
    metaKey?: string,
  ) => void;
  onEventCreate: (params: CreateAppointmentDialogParams) => void;
  onEventCreated: (created: boolean, id?: number) => void;
  onEventUpdated: (id?: number, forceRerender?: boolean) => void;
  onEventDeleted: (id?: number) => void;
  onEventDetails: (entity: Entity) => void;
  onEventPanelExtra: (expanded?: boolean) => void;
};

export const appointmentsEventChannel = createEventBus<AppointmentsEventChannel>();

export type BillingEventChannel = {
  // charges
  onChargesDataChange: (encounterId?: number, claimId?: number, statusUpdate?: boolean) => void;
  onChargesRowToggle: (encounterId: number) => void;
  onChargesPanelOpen: (entity: Entity, type: string) => void;
  // prices
  onPriceDialogOpen: (billingChargeInfo: ChargePolicyDialogParams) => void;
  onPricesChanged: () => void;
};
export const billingEventChannel = createEventBus<BillingEventChannel>();

type ClaimEventChannel = {
  onClaimCreate: (encounterId: number, openAfter?: boolean) => void;
  onClaimCreating: (encounterId: number) => void;
  onClaimSplit: (claim: Claim) => void;
  onClaimSplitOpen: (claim: Claim) => void;
  onSecondaryClaimCreateOpen: (params: SecondaryClaimDialogParams) => void;
  onClaimTransmissionsOpen: (transmissions: ClaimTransmissionDTO[]) => void;
};
export const claimEventChannel = createEventBus<ClaimEventChannel>();

export type PaymentsEventChannel = {
  // payments
  onPaymentDialogOpen: (params: PaymentDialogParams | null) => void;
  onEOBDialogOpen: (params: EOBDialogParams | null) => void;
  onPaymentsDataChanged: (type: PaymentViewName) => void;
  onPaymentPrint: (payment: Payment, onComplete?: () => void) => void;
  onPaymentDelete: (payment: Payment, onComplete?: () => void) => void;
  onSquarePaymentUpdate: (paymentStatus: PaymentStatus) => void;
  onPatientBalanceCollect: (paymentStatus?: PaymentStatus) => void;
  onChangeEOBAdjustmentCode: (params: EOBAdjustmentCodeParams) => void;
  onEOBAllocationDelete: (claimId: number, id?: number) => void;
  onInsurancePaymentDialogOpen: (insurancePayment: InsurancePaymentDTO) => void;
};

export const paymentsEventChannel = createEventBus<PaymentsEventChannel>();

type TodosEventChannel = {
  onToDosChanged: (toDoId?: number) => void;
  onOpenToDo: (toDoId?: number) => void;
  onCreateToDo: (params: Omit<ToDoDialogParams, 'onClose'> | null) => void;
};

export const toDosEventChannel = createEventBus<TodosEventChannel>();

type PatientEventChannel = {
  onStickiesChanged: (patientId: number) => void;
  onCreatePatientDialog: (params: PatientDialogParams) => void;
  onPatientCreated: (patient: PatientCompact) => void;
};

export const patientEventChannel = createEventBus<PatientEventChannel>();

type FaxEventChannel = {
  onFaxChange: () => void;
  onUpdateFaxDetails: (id: number) => void;
  onOpenContacts: (contact?: FaxContactDTO) => void;
  onContactChange: () => void;
  onOpenSendFax: (contact?: FaxContactDTO) => void;
};

export const faxEventChannel = createEventBus<FaxEventChannel>();

export type VisitNoteParams = {
  patientId: number;
  encounterId: number;
} & Record<string, unknown>;

type VisitNoteEventChannel = {
  onOpenRoboscribe: () => void;
  onVisitNoteUpdate: (params: VisitNoteParams) => void;
  onServiceCodesUpdate: (type: string, value: BillingCode | BillingCode[]) => void;
  onMipsUpdate: (mipsList: VisitNoteMipsDTO[]) => void;
};

export const visitNoteEventChannel = createEventBus<VisitNoteEventChannel>();

type ReportEventChannel = {
  onReportCreate: (params: ReportDialogParams) => void;
};

export const reportEventChannel = createEventBus<ReportEventChannel>();

type SettingsEventChannel = {
  onCreateAppointmentType: (params: CreateAppointmentTypeDialogParams) => void;
};

export const settingsEventChannel = createEventBus<SettingsEventChannel>();
