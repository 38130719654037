import dayjs from 'dayjs';
import RelativeTime from 'dayjs/plugin/relativeTime';
import IsBetween from 'dayjs/plugin/isBetween';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import minMax from 'dayjs/plugin/minMax';
import utc from 'dayjs/plugin/utc';

/// @INFO: add more plugins if needed here
dayjs.extend(RelativeTime);
dayjs.extend(IsBetween);
dayjs.extend(CustomParseFormat);
dayjs.extend(minMax);
dayjs.extend(utc);
// dayjs.extend(...); /* another plugin *

export default dayjs;
export { Dayjs } from 'dayjs';
