import React, {
  createContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import FullScreenSpinner from 'core/components/FullScreenSpinner';
import {
  getApiSettingsGetAppointmentSettings,
  getApiSettingsGetWriteOffSettings,
} from 'endpoints';
import {
  useGetUserSettings,
} from 'pages/Dashboard/hooks/useUserSettings';
import {
  CommonComponentPropType,
} from 'pages/Dashboard/types/common';
import {
  SettingsDTO,
  UserSettingDTO,
} from 'dtos';
import usePrefetchedData from 'pages/Dashboard/hooks/usePrefetchedData';

import {
  getToDoSettings,
} from 'pages/Dashboard/pages/Todos/transport';
import {
  ToDoSettingsResponse,
} from 'pages/Dashboard/pages/Todos/types';
import {
  getUserSettings,
} from 'pages/Dashboard/services/api';
import {
  ClaimSettings,
} from 'pages/Dashboard/pages/Billing/types';
import {
  getChargesFilterSettings,
  getClaimSettings,
} from 'pages/Dashboard/pages/Billing/api';
import useUserInfo from 'pages/Dashboard/hooks/useUserInfo';

type Props = {
  settings: Record<string, any>;
  setSettings: (settings: Record<string, any>) => void;
  retrievingSettings: boolean;
};

export const UserSettingsContext = createContext<Props>({
  settings: {},
  setSettings: () => {},
  retrievingSettings: false,
});

export default function UserSettingsProvider({ children }: CommonComponentPropType) {
  const userInfo = useUserInfo();
  const { data, isLoading: loadingUserSettings } = useGetUserSettings();
  // temporary prefetching is here
  const { isLoading: loadingUserInfo } = usePrefetchedData<UserSettingDTO>({
    key: 'user-view-settings',
    fetchFn: getUserSettings,
  });
  const { isLoading: loadingChargesSettings } = usePrefetchedData<SettingsDTO>({
    key: 'charge-filter-settings',
    fetchFn: getChargesFilterSettings,
  });

  const { isLoading: loadingEncounterMetadata } = usePrefetchedData<SettingsDTO>({
    key: 'appointment-settings',
    fetchFn: getApiSettingsGetAppointmentSettings,
  });
  const { isLoading: loadingToDoMetadata } = usePrefetchedData<ToDoSettingsResponse>({
    key: 'to-do-settings',
    fetchFn: getToDoSettings,
  });

  const { isLoading: loadingClaimMetadata } = usePrefetchedData<ClaimSettings>({
    key: 'claim-settings',
    fetchFn: getClaimSettings,
  });

  const { isLoading: loadingPaymentsMetadata } = usePrefetchedData<SettingsDTO>({
    key: 'payment-metadata',
    fetchFn: getApiSettingsGetWriteOffSettings,
  });

  const loadingList = [
    loadingUserSettings,
    loadingChargesSettings,
    loadingEncounterMetadata,
    loadingToDoMetadata,
    loadingUserInfo,
    loadingClaimMetadata,
    loadingPaymentsMetadata,
  ];

  const loading = useMemo(() => (
    loadingList.some((isLoading) => isLoading) || isNil(userInfo)
  ), [...loadingList, userInfo]);

  const [settings, setSettings] = useState<Record<string, any>>({});
  const value = useMemo(() => ({
    settings,
    setSettings,
    retrievingSettings: loading,
  }), [settings, setSettings, loading]);

  useEffect(() => {
    if (!isEmpty(data) && !loadingUserSettings) {
      setSettings(JSON.parse(data?.preferenceJson ?? '{}'));
    }
  }, [data, loadingUserSettings]);

  return (
    <UserSettingsContext.Provider value={value}>
      {loading ? <FullScreenSpinner /> : children}
    </UserSettingsContext.Provider>
  );
}
