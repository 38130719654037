import React, {
  lazy,
} from 'react';
import {
  Navigate,
  RouteObject,
} from 'react-router-dom';
import {
  lazyLoadComponent,
} from 'utils/router';

export const settingsRoutes: RouteObject[] = [{
  path: '/settings/appointment-types',
  element: lazyLoadComponent(
    lazy(() => import('./Appointments')),
  ),
}, {
  path: '/settings',
  element: <Navigate to="/settings/appointment-types" replace />,
}];
