import {
  AxiosError,
} from 'axios';
import React, {
  ReactNode,
} from 'react';
import isNil from 'lodash/isNil';
import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';
import {
  environment,
} from 'env';
import Button from 'pages/Dashboard/components/Button';
import PopulateTextLogo from 'core/icons/populateLogo';

type ErrorBoundaryState = {
  error: unknown;
};

type ErrorBoundaryProps = {
  children: ReactNode;
};

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error) {
    Sentry.captureException(error);

    if (environment === 'prod') {
      mixpanel.track('appCrashed');
    }

    return { error };
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;
    const hasError = !isNil(error);

    const axiosError = error as AxiosError;

    const resourceDoesntExist = axiosError?.response?.status === 410;
    const message = resourceDoesntExist
      ? axiosError?.response?.data as string
      : 'Oops, something went wrong.';

    if (hasError) {
      return (
        <div className="relative bg-black text-white h-[100vh]">
          <div className="absolute">
            <PopulateTextLogo className="!w-full ml-4 mt-4 mr-auto" />
          </div>
          <div className="flex flex-col space-y-10 justify-center items-center h-full">
            <h1 className="text-xl font-semibold">
              {message}
            </h1>
            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={() => {
                if (resourceDoesntExist) {
                  window.location.replace('/encounters');
                } else {
                  window.location.reload();
                }
              }}
            >
              {resourceDoesntExist ? 'Home Screen' : 'Restart App'}
            </Button>
          </div>
        </div>
      );
    }

    return children;
  }
}
