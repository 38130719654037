import {
  useEffect,
  useState,
} from 'react';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from 'react-query';
import printJS from 'print-js';
import head from 'lodash/head';
import compact from 'lodash/compact';
import map from 'lodash/map';
import uniqueId from 'lodash/uniqueId';
import isNil from 'lodash/isNil';
import {
  BillingMultiMediaResponseDTO,
  BillingMultiMediaUploadDTO,
  InsurancePaymentRequestDTO,
  MultiMediaResponse,
  ClaimBundleDTO,
  MultiMediaResponseDTO,
  EOBWithInsurancePaymentRequestDTO,
  EOBWithInsurancePaymentResponseDTO,
  CancelledTerminalCheckoutDTO,
  CancelTerminalCheckoutRequest,
  PatientAsPayerPaymentResponseDTOBase,
  SplitClaimsDTO,
  CreateSecondaryClaimDTO,
  ClaimAdjustmentReasonCodeDTO,
  ChargeMasterDTO,
  CustomChargeMasterDTO,
  None,
  PatientStatementRequestDTO,
  SettingsDTO,
  VisitNoteBillingDataWithPaymentMethodsViewDTO,
  EOBDataForClaimViewDTO,
  PatientInvoiceRequestDTO,
  MarkClaimStatusDTO,
  PatientStatementReminderResponseDTO,
  PatientAsPayerPaymentRequestDTO,
} from 'dtos';
import {
  formatDateTimeOffset,
} from 'utils/date';
import {
  MediaCategory,
  MediaOriginatorType,
} from 'pages/Dashboard/utils/constants';
import usePrefetchedData from 'pages/Dashboard/hooks/usePrefetchedData';
import {
  getMultiMedia,
} from 'pages/Dashboard/services/api';
import {
  deletePayment,
  uploadBillingMedia,
  createEOB,
  modifyEOB,
  deleteEOB,
  addEOBPayment,
  deleteClaim,
  createClaim,
  saveClaim,
  markClaimStatus,
  getClaimSettings,
  invokeClaimRequest,
  printClaim,
  saveClaimSplit,
  getClaimSplitsByEncounter,
  createSecondaryClaim,
  getPatientPaymentFilterSettings,
} from 'pages/Dashboard/pages/Billing/api';
import {
  ClaimSettings,
  ClaimSplitsBundle,
  PatientInvoice,
  PatientStatementRequest,
  Price,
} from 'pages/Dashboard/pages/Billing/types';
import {
  CollectPaymentData,
  normalizeClaimSplits,
  normalizePatientInvoice,
  paymentNotificationsByVariant,
  serializeEOBWithInsurancePaymentRequest,
  serializePrice,
  serializePatientStatementPayload,
  serializeCollectPaymentData,
} from 'pages/Dashboard/pages/Billing/helpers';
import {
  useSignalRSubscribeToAllOnce,
} from 'pages/Dashboard/hooks/useSignalR';
import {
  SuffixEndpoints,
} from 'core/signalR/types';
import {
  paymentsEventChannel,
} from 'pages/Dashboard/services/eventChannels';
import useUserInfo from 'pages/Dashboard/hooks/useUserInfo';
import {
  useNotify,
} from 'core/hooks';
import {
  AlertVariant,
} from 'pages/Dashboard/components/Alert';
import {
  deleteApiChargeDeleteChargePolicy,
  deleteApiChargeDeleteCustomCodeChargePolicy,
  getApiClaimSearchAdjustmentReasonCode,
  postApiBillingPrintStatementPdf,
  postApiBillingSendStatementPdf,
  getApiSettingsGetWriteOffSettings,
  postApiChargeCreateChargePolicy,
  postApiChargeCreateCustomCodeChargePolicy,
  postApiChargeModifyChargePolicy,
  postApiChargeModifyCustomCodeChargePolicy,
  postApiClaimClaimRequestResponseStatusClaimId,
  getApiBillingGetVisitNoteBillingDataWithPaymentMethods,
  getApiBillingGetEOBByPatientPatientId,
  getApiBillingGetEOB,
  postApiBillingGetPatientStatement,
  postApiBillingPrintInvoicePdf,
  getApiBillingGetStatementLastSentInfo,
  postApiBillingAddOrUpdatePatientPayment,
  postApiSquarePaymentsCancelTerminalCheckout,
  getApiClaimGetClaimByIdClaimId,
} from 'endpoints';
import {
  normalizeFilterableSettings,
} from 'core/Filters/util';
import {
  ReasonCode,
} from 'pages/Dashboard/pages/Billing/Payments/EOBDialog/EOBAdjustmentCodePopover';
import {
  isEmptyString,
} from 'utils/misc';

const transportFunctionsByType = Object.freeze({
  cpt: {
    create: postApiChargeCreateChargePolicy,
    update: postApiChargeModifyChargePolicy,
    delete: deleteApiChargeDeleteChargePolicy,
  },
  custom: {
    create: postApiChargeCreateCustomCodeChargePolicy,
    update: postApiChargeModifyCustomCodeChargePolicy,
    delete: deleteApiChargeDeleteCustomCodeChargePolicy,
  },
});

export function useUpsertServices():
  UseMutationResult<ChargeMasterDTO | CustomChargeMasterDTO, Error, Price> {
  return useMutation<ChargeMasterDTO | CustomChargeMasterDTO, Error, Price>(
    (data: Price) => {
      const { isCustom, id } = data;
      const { create, update } = transportFunctionsByType[isCustom ? 'custom' : 'cpt'];
      const serialized = serializePrice(data);
      return isNil(id) ? create(serialized) : update(serialized);
    },
  );
}

export function useDeleteChargePolicy(): UseMutationResult<
  ChargeMasterDTO | CustomChargeMasterDTO,
  Error,
  Partial<Price>> {
  return useMutation<
    ChargeMasterDTO | CustomChargeMasterDTO,
    Error,
    Partial<Price>>(async ({ id, isCustom }) => {
      const deleteChargePolicy = isCustom
        ? deleteApiChargeDeleteCustomCodeChargePolicy
        : deleteApiChargeDeleteChargePolicy;
      const response = await deleteChargePolicy({ id });
      return response;
    });
}

export function useUpsertEOB():
  UseMutationResult<EOBWithInsurancePaymentResponseDTO, Error, EOBWithInsurancePaymentRequestDTO> {
  return useMutation<EOBWithInsurancePaymentResponseDTO, Error, EOBWithInsurancePaymentRequestDTO>(
    (data: EOBWithInsurancePaymentRequestDTO) => (
      isNil(data.eob?.eobId) || data.eob?.eobId === 0
        ? createEOB(serializeEOBWithInsurancePaymentRequest(data))
        : modifyEOB(serializeEOBWithInsurancePaymentRequest(data))
    ),
  );
}

export function useDeletePayment(): UseMutationResult<void, Error, number> {
  return useMutation<void, Error, number>(deletePayment);
}

export function usePrintPayment():
  UseMutationResult<MultiMediaResponseDTO, Error, PatientInvoiceRequestDTO> {
  return useMutation<
   MultiMediaResponseDTO, Error, PatientInvoiceRequestDTO
   >(postApiBillingPrintInvoicePdf);
}

export function useGetInvoiceCommuniqueInfo(
  patientId: number,
): UseQueryResult<PatientStatementReminderResponseDTO | void, Error> {
  return useQuery<PatientStatementReminderResponseDTO | void, Error>(
    ['patientInvoiceData', patientId],
    () => getApiBillingGetStatementLastSentInfo({ patientId }),
  );
}

export function useDeleteEOB(): UseMutationResult<void, Error, number> {
  return useMutation<void, Error, number>(deleteEOB);
}

export function useGetEOBDataForBilling(
  id: number,
  dateRange: Date[],
): UseQueryResult<
  EOBDataForClaimViewDTO[], Error> {
  const [startDate, endDate] = dateRange.map(formatDateTimeOffset);
  return useQuery<EOBDataForClaimViewDTO[], Error>(
    ['eob-claims-data', id, ...dateRange],
    () => getApiBillingGetEOBByPatientPatientId(id, {
      startDate,
      endDate,
    }),
    { enabled: id > 0 },
  );
}

export function useGetEncountersForBillingWithPaymentInfo(
  patientId: number,
  dateRange: Date[],
): UseQueryResult<VisitNoteBillingDataWithPaymentMethodsViewDTO, Error> {
  return useQuery<VisitNoteBillingDataWithPaymentMethodsViewDTO, Error>(
    ['payment-encounters', patientId, ...dateRange],
    () => {
      const [startDate, endDate] = dateRange.map(formatDateTimeOffset);
      return getApiBillingGetVisitNoteBillingDataWithPaymentMethods({
        patientId,
        startDate,
        endDate,
      });
    },
    { enabled: patientId > 0 },
  );
}

export function useUploadBillingMedia(): UseMutationResult<
  BillingMultiMediaResponseDTO,
  Error,
  BillingMultiMediaUploadDTO> {
  return useMutation<
    BillingMultiMediaResponseDTO,
    Error,
    BillingMultiMediaUploadDTO>(uploadBillingMedia);
}

export function useGetBillingMediaByCategories(
  id: number,
  categories: MediaCategory[],
): UseQueryResult<MultiMediaResponse[], Error> {
  return useQuery<MultiMediaResponse[], Error>(
    ['multimedia', id, ...categories],
    () => getMultiMedia({
      generateUrls: true,
      list: categories.map((category) => ({
        originatorType: MediaOriginatorType.Billing,
        originatorId: id,
        mediaCategory: category,
      })),
    }),
    { enabled: id > 0 && categories.length > 0 },
  );
}

export function useAddEOBPayment(): UseMutationResult<void, Error, InsurancePaymentRequestDTO> {
  return useMutation<void, Error, InsurancePaymentRequestDTO>(addEOBPayment);
}

export function useCreateClaim(): UseMutationResult<ClaimBundleDTO, Error, number> {
  return useMutation<ClaimBundleDTO, Error, number>(createClaim);
}

export function useClaimSettings(): ClaimSettings {
  const { data } = usePrefetchedData<ClaimSettings>({
    key: 'claim-settings',
    fetchFn: getClaimSettings,
  });

  return data ?? {};
}

export function useDeleteClaim(): UseMutationResult<void, Error, number> {
  return useMutation<void, Error, number>(deleteClaim);
}

export function useGetClaimById(id: number = 0):
  UseQueryResult<ClaimBundleDTO, Error> {
  return useQuery<ClaimBundleDTO, Error>(
    ['claim', id],
    () => getApiClaimGetClaimByIdClaimId(id),
    { enabled: id > 0 },
  );
}

export function useGetClaimSplitsByEncounter(
  id: number = 0,
): UseQueryResult<ClaimSplitsBundle, Error> {
  return useQuery<ClaimSplitsBundle, Error>(
    ['claim', 'splits', id],
    async () => {
      const response = await getClaimSplitsByEncounter(id);
      return {
        ...response,
        splitClaims: normalizeClaimSplits(response),
      };
    },
    { enabled: id > 0 },
  );
}

export function useSaveClaim(): UseMutationResult<ClaimBundleDTO, Error, ClaimBundleDTO> {
  return useMutation<ClaimBundleDTO, Error, ClaimBundleDTO>(saveClaim);
}

export function useMarkClaimStatus(): UseMutationResult<ClaimBundleDTO, Error, MarkClaimStatusDTO> {
  return useMutation<ClaimBundleDTO, Error, MarkClaimStatusDTO>(markClaimStatus);
}

export function useCheckClaimStatus(): UseMutationResult<ClaimBundleDTO, Error, number> {
  return useMutation<ClaimBundleDTO, Error, number>(postApiClaimClaimRequestResponseStatusClaimId);
}

export function useSaveSplitClaim(): UseMutationResult<void, Error, SplitClaimsDTO> {
  return useMutation<void, Error, SplitClaimsDTO>(saveClaimSplit);
}

export function useCreateSecondaryClaim(): UseMutationResult<
  ClaimBundleDTO, Error, CreateSecondaryClaimDTO> {
  return useMutation<ClaimBundleDTO, Error, CreateSecondaryClaimDTO>(createSecondaryClaim);
}

export function useClaimRequest(): UseMutationResult<ClaimBundleDTO, Error, number> {
  return useMutation<ClaimBundleDTO, Error, number>(invokeClaimRequest);
}

export function usePatientInvoiceData(
  data: PatientStatementRequestDTO,
): UseQueryResult<PatientInvoice, Error> {
  return useQuery<PatientInvoice, Error>(
    ['patientInvoiceData', data],
    async () => {
      const response = await postApiBillingGetPatientStatement(data);
      return normalizePatientInvoice(response);
    },
  );
}

export function usePrinPatientStatement():
  UseMutationResult<MultiMediaResponseDTO, Error, PatientStatementRequest> {
  return useMutation<MultiMediaResponseDTO, Error, PatientStatementRequest>(
    (data: PatientStatementRequest) => {
      const serialized = serializePatientStatementPayload(data);
      return postApiBillingPrintStatementPdf(serialized);
    },
  );
}

export function useSendPatientStatement():
  UseMutationResult<None, Error, PatientStatementRequest> {
  return useMutation<None, Error, PatientStatementRequest>(
    (data: PatientStatementRequest) => {
      const serialized = serializePatientStatementPayload(data);
      return postApiBillingSendStatementPdf(serialized);
    },
  );
}

export function usePrintClaim() {
  const [claimRequest, setClaimRequest] = useState<[number, string] | null>(null);
  const { data, isFetching } = useQuery<MultiMediaResponseDTO, Error>(
    claimRequest ?? '',
    () => printClaim((claimRequest ?? [])[0] ?? 0),
    { enabled: claimRequest !== null },
  );

  useEffect(() => {
    if (!isFetching && data && claimRequest !== null) {
      printJS({
        printable: head(data?.list)?.url?.value,
        type: 'pdf',
        showModal: true,
      });
      setClaimRequest(null);
    }
  }, [data, isFetching, claimRequest]);

  return {
    isPrinting: isFetching,
    print: (id: number) => {
      setClaimRequest([id, uniqueId('print-claim__')]);
    },
  };
}

export function useUpsertPatientPayment() {
  const { mutateAsync: cancel } = useMutation<
    CancelledTerminalCheckoutDTO,
    Error,
    CancelTerminalCheckoutRequest
    >(postApiSquarePaymentsCancelTerminalCheckout);

  const { mutateAsync: collect } = useMutation<
    PatientAsPayerPaymentResponseDTOBase,
    Error,
    PatientAsPayerPaymentRequestDTO
    >(postApiBillingAddOrUpdatePatientPayment);

  return { cancel, collect };
}

export function usePatientPayment() {
  const [checkoutId, setCheckoutId] = useState<string | null>(null);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const { cancel, collect } = useUpsertPatientPayment();

  const collectPayment = async (data: CollectPaymentData) => {
    const serialized = serializeCollectPaymentData(data);
    const response = await collect(serialized);
    setCheckoutId(response.checkoutId ?? null);
  };

  const cancelPayment = async () => {
    try {
      setIsCancelling(true);
      const response = await cancel({ checkoutId });
      if (!isNil(response)) {
        setCheckoutId(null);
      }
    } catch (e) {
      console.error(e);
      setIsCancelling(false);
    }
  };

  return {
    checkoutId,
    collectPayment,
    cancelPayment,
    isCancelling,
    setIsCancelling,
  };
}

export function useSquarePaymentUpdate() {
  const userInfo = useUserInfo();

  useSignalRSubscribeToAllOnce(
    SuffixEndpoints.SquareTerminalPayment,
    (signalRConnection, ...checkoutStatus) => {
      if (checkoutStatus.length > 1) {
        const [metadata, [status]] = checkoutStatus;
        if (checkoutStatus && status?.userId === userInfo?.user?.userId) {
          paymentsEventChannel.emit('onSquarePaymentUpdate', status);
        }
      }
    },
  );
}

export function useNotifyPaymentUpdate() {
  const notify = useNotify();
  return (isComplete?: boolean, isCancelled?: boolean) => {
    const alertVariant = isComplete
      ? AlertVariant.SUCCESS : AlertVariant.ERROR;

    const infoVariant = isCancelled ? AlertVariant.INFO : null;

    notify(paymentNotificationsByVariant[isNil(infoVariant) ? alertVariant : infoVariant]);
  };
}

export function useSearchAdjustmentReasonCode(
  searchString: string,
  enabled = true,
): UseQueryResult<ReasonCode[], Error> {
  return useQuery<ReasonCode[], Error>(
    ['adjustment-reason-codes', searchString],
    async () => {
      const response = await getApiClaimSearchAdjustmentReasonCode({ searchString });
      return ((response ?? []) as ClaimAdjustmentReasonCodeDTO[])
        .map(({ description, reasonCode }) => ({
          code: reasonCode ?? undefined,
          description,
        }));
    },
    { enabled },
  );
}

export function useGetPatientPaymentFilterSettings() {
  const { data } = usePrefetchedData<SettingsDTO>({
    key: 'patient-payment-filter-settings',
    fetchFn: getPatientPaymentFilterSettings,
  });

  return normalizeFilterableSettings(data?.filterableSettings ?? []);
}

export function useWriteOffOptions(): string[] {
  const { data } = usePrefetchedData<SettingsDTO>({
    key: 'payment-metadata',
    fetchFn: getApiSettingsGetWriteOffSettings,
  });

  return compact(map(data?.writeOffSettings ?? [], 'reason'));
}

export function useGetEOBByNumber(
  eobNumber?: string | null,
): UseQueryResult<EOBWithInsurancePaymentResponseDTO, Error> {
  return useQuery(
    ['eob', eobNumber],
    () => getApiBillingGetEOB({ eobNumber: eobNumber ?? undefined }),
    {
      enabled: !isEmptyString(eobNumber),
      keepPreviousData: true,
    },
  );
}
