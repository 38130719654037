import React, {
  createContext,
  useCallback,
  useMemo,
  useState,
  ReactNode,
  PropsWithChildren,
} from 'react';
import Dialog from 'pages/Dashboard/components/Dialog';

type DialogProps = {
  title?: string;
  className?: string;
  cancelText?: string;
  confirmText?: string;
  onConfirm?: () => void;
  onClose?: () => void;
  disableConfirmButton?: boolean;
  disableBackdropClick?: boolean;
  content: ReactNode;
  showActions?: boolean;
  hideCloseBtn?: boolean;
  dialogClassName?: string;
  closeBtnClasses?: string;
  titleClassName?: string;
};

type ContextValues = {
  openDialog: (props: DialogProps) => void;
  closeDialog: () => void;
};

export const ConfirmationDialogContext = createContext<ContextValues>({
  openDialog: () => {},
  closeDialog: () => {},
});

export default function ConfirmationDialogProvider({ children }: PropsWithChildren) {
  const DEFAULT_PROPS = {
    title: '',
    onConfirm: () => {},
    content: null,
    showActions: true,
  };
  const [props, setProps] = useState<DialogProps>(DEFAULT_PROPS);
  const [open, setOpen] = useState<boolean>(false);

  // FIXME: Temporary Solution to prevent double click
  const [confirming, setConfirming] = useState<boolean>(false);

  const openDialog = (_props: DialogProps) => {
    setProps({
      ...DEFAULT_PROPS,
      ..._props,
    });
    setConfirming(false);
    setOpen(true);
  };

  const onConfirm = () => {
    setConfirming(true);
    props?.onConfirm?.();
  };

  const closeDialog = useCallback(() => setOpen(false), []);

  const value = useMemo(() => ({
    openDialog,
    closeDialog,
  }), [openDialog, closeDialog]);

  return (
    <ConfirmationDialogContext.Provider value={value}>
      <Dialog
        closeBtnClasses={props.closeBtnClasses}
        dialogClassName={props.dialogClassName}
        className={props.className}
        title={props.title}
        titleClassName={props.titleClassName}
        open={open}
        onClose={props.onClose ?? closeDialog}
        disableBackdropClick={props.disableBackdropClick}
        onConfirm={onConfirm}
        confirmText={props.confirmText}
        cancelText={props.cancelText}
        disableConfirmButton={confirming || props.disableConfirmButton}
        content={props.content}
        showActions={props.showActions}
        hideCloseBtn={props.hideCloseBtn}
      />
      {children}
    </ConfirmationDialogContext.Provider>
  );
}
