import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
  PropsWithChildren,
} from 'react';
import compact from 'lodash/compact';
import noop from 'lodash/noop';
import head from 'lodash/head';

type SelectableContextProps = {
  isSelected: (id: number | string) => boolean;
  toggle: (id: number | string) => void;
  selected: Set<number | string>;
  clear: () => void;
  add: (...params: (number | string)[]) => void;
  remove: (...params: (number | string)[]) => void;
};

export const SelectableContext = createContext<SelectableContextProps>({
  isSelected: () => false,
  toggle: noop,
  selected: new Set(),
  clear: noop,
  add: noop,
  remove: noop,
});

type Props = PropsWithChildren & {
  selected?: (number | string)[];
  isSolo?: boolean;
};

export default function SelectableProvider({
  children,
  isSolo = false,
  selected: _selected = [],
}: Props) {
  const [selected, select] = useState<Set<number | string>>(new Set(_selected));

  const isSelected = useCallback((id: number | string) => selected.has(id), [selected]);

  const toggle = useCallback((id: number | string) => {
    select((prevSelected) => {
      if (prevSelected.has(id)) {
        prevSelected.delete(id);
      } else {
        if (isSolo) {
          prevSelected.clear();
        }
        prevSelected.add(id);
      }
      return new Set(prevSelected);
    });
  }, []);

  const add = useCallback((...params: (number | string)[]) => {
    select((prevSelected) => {
      const newSelected = [];
      if (isSolo) {
        prevSelected.clear();
        newSelected.push(head(params));
      } else {
        newSelected.push(...Array.from(prevSelected), ...params);
      }
      return new Set(compact(newSelected));
    });
  }, []);

  const remove = useCallback((...params: (number | string)[]) => {
    select((prevSelected) => {
      params.forEach((param) => prevSelected.delete(param));
      return new Set(prevSelected);
    });
  }, []);

  const clear = useCallback(() => select(new Set()), [select]);

  const value = useMemo(() => ({
    isSelected,
    toggle,
    selected,
    clear,
    add,
    remove,
  }), [isSelected, toggle, selected, select, add, remove]);

  return (
    <SelectableContext.Provider value={value}>
      {children}
    </SelectableContext.Provider>
  );
}
