import {
  lazy,
} from 'react';
import {
  RouteObject,
} from 'react-router-dom';
import {
  lazyLoadComponent,
} from 'utils/router';

export const faxRoutes: RouteObject[] = [{
  path: '/fax',
  element: lazyLoadComponent(
    lazy(() => import('./List')),
  ),
}, {
  path: '/fax/contacts',
  element: lazyLoadComponent(
    lazy(() => import('../Contacts')),
  ),
}];
