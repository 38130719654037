import {
  useQuery,
  UseQueryResult,
} from 'react-query';
import {
  getEncounters,
} from 'pages/Dashboard/pages/Encounters/pages/List/services/api';
import {
  Encounter,
} from 'pages/Dashboard/pages/Encounters/pages/List/types/encounter';

export default function useEncounters(date: string): UseQueryResult<Encounter[], Error> {
  return useQuery<Encounter[], Error>(
    ['encounters', date],
    () => getEncounters(date),
  );
}
