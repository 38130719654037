import axios, {
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import {
  baseApiUrl,
} from 'env';
// @INFO: !!! uncomment if you need to mock API
// eslint-disable-next-line import/no-extraneous-dependencies
// import MockAdapter from 'axios-mock-adapter';
// mock samples below

export const axiosInstance = axios.create({ baseURL: baseApiUrl });
const axiosMockInstance = axios.create();
const mockedUrls = new Set<string>([
]);

export function sendRequest<T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
  isMock = false,
): Promise<T> {
  const httpClient = isMock || mockedUrls.has(config.url?.toLowerCase() ?? '')
    ? axiosMockInstance : axiosInstance;
  const source = axios.CancelToken.source();
  const promise = httpClient({
    ...config,
    ...options,
    cancelToken: source.token,
  }).then(({ data }: AxiosResponse<T>) => data);
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled.');
  };
  return promise;
}

// @INFO: !!! uncomment if you need to mock API
// const mockHttpAdapter = new MockAdapter(axiosMockInstance);
// e.g
// mockHttpAdapter
//   .onPost('/api/EFax/GetFaxes')
//   .reply(200, faxList);
