import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import {
  CommonOption,
} from 'pages/Dashboard/pages/Appointments/pages/List/types/appointment';
import {
  SocialHistory,
} from 'pages/Dashboard/pages/Charts/pages/Details/components/SocialHistories/SocialHistoryInfo';

export type USStateType = {
  name: string;
  abbreviation: string | null;
};

export const US_STATES: USStateType[] = [{
  name: 'Alabama',
  abbreviation: 'AL',
}, {
  name: 'Alaska',
  abbreviation: 'AK',
}, {
  name: 'Arizona',
  abbreviation: 'AZ',
}, {
  name: 'Armed Forces',
  abbreviation: 'AA',
}, {
  name: 'Armed Forces',
  abbreviation: 'AE',
}, {
  name: 'Armed Forces',
  abbreviation: 'AP',
}, {
  name: 'Arkansas',
  abbreviation: 'AR',
}, {
  name: 'California',
  abbreviation: 'CA',
}, {
  name: 'Colorado',
  abbreviation: 'CO',
}, {
  name: 'Connecticut',
  abbreviation: 'CT',
}, {
  name: 'Delaware',
  abbreviation: 'DE',
}, {
  name: 'District Of Columbia',
  abbreviation: 'DC',
}, {
  name: 'Florida',
  abbreviation: 'FL',
}, {
  name: 'Georgia',
  abbreviation: 'GA',
}, {
  name: 'Hawaii',
  abbreviation: 'HI',
}, {
  name: 'Idaho',
  abbreviation: 'ID',
}, {
  name: 'Illinois',
  abbreviation: 'IL',
}, {
  name: 'Indiana',
  abbreviation: 'IN',
}, {
  name: 'Iowa',
  abbreviation: 'IA',
}, {
  name: 'Kansas',
  abbreviation: 'KS',
}, {
  name: 'Kentucky',
  abbreviation: 'KY',
}, {
  name: 'Louisiana',
  abbreviation: 'LA',
}, {
  name: 'Maine',
  abbreviation: 'ME',
}, {
  name: 'Maryland',
  abbreviation: 'MD',
}, {
  name: 'Massachusetts',
  abbreviation: 'MA',
}, {
  name: 'Michigan',
  abbreviation: 'MI',
}, {
  name: 'Minnesota',
  abbreviation: 'MN',
}, {
  name: 'Mississippi',
  abbreviation: 'MS',
}, {
  name: 'Missouri',
  abbreviation: 'MO',
}, {
  name: 'Montana',
  abbreviation: 'MT',
}, {
  name: 'Nebraska',
  abbreviation: 'NE',
}, {
  name: 'Nevada',
  abbreviation: 'NV',
}, {
  name: 'New Hampshire',
  abbreviation: 'NH',
}, {
  name: 'New Jersey',
  abbreviation: 'NJ',
}, {
  name: 'New Mexico',
  abbreviation: 'NM',
}, {
  name: 'New York',
  abbreviation: 'NY',
}, {
  name: 'North Carolina',
  abbreviation: 'NC',
}, {
  name: 'North Dakota',
  abbreviation: 'ND',
}, {
  name: 'Ohio',
  abbreviation: 'OH',
}, {
  name: 'Oklahoma',
  abbreviation: 'OK',
}, {
  name: 'Oregon',
  abbreviation: 'OR',
}, {
  name: 'Pennsylvania',
  abbreviation: 'PA',
}, {
  name: 'Rhode Island',
  abbreviation: 'RI',
}, {
  name: 'South Carolina',
  abbreviation: 'SC',
}, {
  name: 'South Dakota',
  abbreviation: 'SD',
}, {
  name: 'Tennessee',
  abbreviation: 'TN',
}, {
  name: 'Texas',
  abbreviation: 'TX',
}, {
  name: 'Utah',
  abbreviation: 'UT',
}, {
  name: 'Vermont',
  abbreviation: 'VT',
}, {
  name: 'Virginia',
  abbreviation: 'VA',
}, {
  name: 'Washington',
  abbreviation: 'WA',
}, {
  name: 'West Virginia',
  abbreviation: 'WV',
}, {
  name: 'Wisconsin',
  abbreviation: 'WI',
}, {
  name: 'Wyoming',
  abbreviation: 'WY',
}];

export const pageSizeOptions: number[] = [25, 50, 100];
export const weekDays = Object.freeze(['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA']);
export const daysOfWeek: CommonOption[] = weekDays
  .map((day, index) => ({ label: day, id: index }));

export const SOCIAL_HISTORY_OPTIONS = {
  tobaccoUsedInLast12Months: {
    options: Object.freeze([
      { label: 'No', value: 'No' },
      { label: 'One to ten', value: '1 - 10 times' },
      { label: 'Eleven plus', value: '11+ times' },
    ]),
  },
  alcoholConsumedInLast12Months: {
    options: Object.freeze([
      { label: 'Zero to one', value: '0 - 1 times' },
      { label: 'Two to ten', value: '2 - 10 times' },
      { label: 'Eleven plus', value: '11+ times' },
    ]),
  },
  haveYouEverPutInAnAdvanceCarePlan: {
    options: Object.freeze([
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
      { label: 'Decline to answer', value: 'Decline to answer' },
    ]),
  },
  hadOneOrMoreMinorOrFallsInLast12Months: {
    options: Object.freeze([
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
    ]),
  },
};

export const optionsByCategory = mapValues(SOCIAL_HISTORY_OPTIONS, (category) => (
  keyBy(category.options, 'value')
));

const valuesByCategory = mapValues(SOCIAL_HISTORY_OPTIONS, (category) => (
  category.options.map((option) => option.value)
));

export const socialHistoryData: SocialHistory[] = [
  {
    id: 1,
    label: 'Used tobacco products in the last 12 months',
    key: 'tobaccoUsedInLast12Months',
    selectOptions: valuesByCategory.tobaccoUsedInLast12Months,
    chartDetailLabel: 'Tobacco',
  },
  {
    id: 2,
    label: 'Had more than 4-5 drinks in the last 12 months',
    key: 'alcoholConsumedInLast12Months',
    selectOptions: valuesByCategory.alcoholConsumedInLast12Months,
    chartDetailLabel: 'Heavy Drinking',
  },
  {
    id: 3,
    label: 'Some form of Advanced Care planing',
    key: 'haveYouEverPutInAnAdvanceCarePlan',
    selectOptions: valuesByCategory.haveYouEverPutInAnAdvanceCarePlan,
    chartDetailLabel: 'Advanced Care Plan',
  },
  {
    id: 4,
    label: '1+ major falls or 2+ minor falls in last 12 months',
    key: 'hadOneOrMoreMinorOrFallsInLast12Months',
    selectOptions: valuesByCategory.hadOneOrMoreMinorOrFallsInLast12Months,
    chartDetailLabel: 'Recent Falls',
  },
];

export const chartData = keyBy(socialHistoryData, 'key');
