import fromPairs from 'lodash/fromPairs';
import keyBy from 'lodash/keyBy';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import sortBy from 'lodash/sortBy';
import toPairs from 'lodash/toPairs';
import uniqueId from 'lodash/uniqueId';
import values from 'lodash/values';
import {
  BillingCode,
  Price,
  ChargesRowData,
  Claim,
  ClaimSplit,
  ExtendedServiceCode,
  PatientInvoice,
  Payment,
  TerminalCardType,
  VisitNoteBillInfo,
  PatientStatementRequest,
} from 'pages/Dashboard/pages/Billing/types';
import {
  ChargesResponseDTO,
  PatientDTO,
  ClaimBundleDTO,
  VisitNoteBillingCodeDTO,
  SplitClaimsDTO,
  PatientAsPayerPaymentRequestDTO,
  EOBWithInsurancePaymentRequestDTO,
  VisitNoteBillingAssessmentCodeDTO,
  ServiceCodeDetailsDTO,
  PatientStatementDTO,
  ChargeMasterDTO,
  CustomChargeMasterDTO,
  PatientStatementRequestDTO,
} from 'dtos';
import {
  formatDateOnly,
  formatDateTimeOffset,
} from 'utils/date';
import dayjs from 'utils/dayjs';
import {
  getDefaultIf,
  isEmptyString,
  setDefaultValueIf,
} from 'utils/misc';
import {
  AppointmentPaymentMethod,
} from 'pages/Dashboard/pages/Appointments/pages/List/types/appointment';
import {
  PatientPaymentTypes,
} from 'dtos/patientPaymentTypes';
import {
  AlertVariant,
} from 'pages/Dashboard/components/Alert';
import {
  normalizePhoneNumber,
} from 'pages/Dashboard/utils/helper';
import pickBy from 'lodash/pickBy';
import {
  withServiceCodesAllocation,
} from 'pages/Dashboard/pages/Billing/Payments/utils';

const paymentNotifications = Object.freeze([{
  variant: AlertVariant.ERROR,
  message: 'Payment Failed, please try again later',
},
{
  variant: AlertVariant.SUCCESS,
  message: 'Payment Received Successfully',
},
{
  variant: AlertVariant.INFO,
  message: 'Payment Cancelled',
},
]);

const achFields: readonly string[] = Object.freeze([
  'senderAccountNumber',
  'senderRoutingNumber',
  'receiverAccountNumber',
  'receiverRoutingNumber',
]);

export const paymentNotificationsByVariant = Object.freeze(keyBy(paymentNotifications, 'variant'));

export function normalizeBillingCodes(
  billingCodes: VisitNoteBillingCodeDTO[],
  serviceCodeDefaults: ServiceCodeDetailsDTO = {},
): BillingCode[] {
  return (billingCodes ?? []).map((billingCode) => ({
    ...billingCode,
    serviceCodeDetails: {
      ...billingCode?.serviceCodeDetails,
      priorAuthCode:
        billingCode?.serviceCodeDetails?.priorAuthCode
        ?? serviceCodeDefaults?.priorAuthCode,
      pos:
        billingCode.serviceCodeDetails?.pos
        ?? serviceCodeDefaults?.pos,
      serviceDate: dayjs(
        billingCode?.serviceCodeDetails?.serviceDate
        ?? serviceCodeDefaults?.serviceDate,
      ),
      drugIdentification: {
        ...(billingCode?.serviceCodeDetails?.drugIdentification ?? {}),
        unitCode: {
          code: billingCode?.serviceCodeDetails?.drugIdentification?.unitCode
            ?? serviceCodeDefaults.drugIdentification?.unitCode
            ?? '',
        },
      },
    },
    __id: billingCode?.visitNoteBillingCodeId?.toString() ?? uniqueId(billingCode?.code ?? ''),
  }));
}

// @TODO: consolidate format billing codes
export const formatBillingCodes = (
  billingCodes: BillingCode[],
  assessmentCodes: VisitNoteBillingAssessmentCodeDTO[],
): VisitNoteBillingCodeDTO[] => {
  const orderIndexes = fromPairs(assessmentCodes.map(({ code }, index) => ([code, index])));
  return billingCodes
    .map(({
      __id,
      linkedICDCodes,
      serviceCodeDetails,
      modifiers,
      ...item
    }: BillingCode) => ({
      ...item,
      linkedICDCodes: sortBy(linkedICDCodes, ({ code }) => orderIndexes[code ?? '']),
      serviceCodeDetails: {
        ...(serviceCodeDetails ?? {}),
        serviceDate: formatDateOnly(serviceCodeDetails?.serviceDate ?? ''),
        drugIdentification: {
          ...serviceCodeDetails?.drugIdentification,
          unitCount: Number(serviceCodeDetails?.drugIdentification?.unitCount) === 0
            ? null : Number(serviceCodeDetails?.drugIdentification?.unitCount),
          unitCode: serviceCodeDetails?.drugIdentification?.unitCode?.code ?? '',
        },
      },
      modifiers: (modifiers ?? [])
        .map((modifier) => ({
          ...modifier,
          description: modifier.description ?? modifier.code,
        })),
    }));
};

const amountKeys: readonly string[] = Object.freeze([
  'allowedAmount',
  'insurancePaidAmount',
  'balance',
  'patientResponsibility',
  'billedAmount',
]);

export function normalizeCharges(
  { charges }: ChargesResponseDTO,
): ChargesRowData[] {
  return charges?.data?.map(({
    claims,
    patient,
    visitDateTime,
    encounterId,
    serviceCodes,
    isFinalized,
    reasonForVisit,
    billingReviewState,
    patientResponsibility,
    patientBalance,
    isOutOfPocket,
    customServiceCodes,
  }) => {
    const keyedSecondaryClaims = keyBy(
      (claims ?? []).filter(({ referencedPrimaryClaimId }) => !isNil(referencedPrimaryClaimId)),
      'referencedPrimaryClaimId',
    ) ?? {};

    const bill = {
      state: billingReviewState,
      serviceCodes: serviceCodes?.map(({
        modifiers, icdCodes, ...serviceCode
      }) => ({
        ...serviceCode,
        modifiers: (modifiers ?? []).map((modifier) => ({ code: modifier })),
        linkedICDCodes: (icdCodes ?? []).map((linkedICDCode) => ({ code: linkedICDCode })),
        serviceCodeDetails: {
          ...serviceCode,
          ...pick(serviceCode ?? [], amountKeys),
          primaryClaimId: serviceCode.primaryClaimId ?? 0,
        },
      })),
      customServiceCodes: customServiceCodes ?? [],
    };

    const codesByClaimIds: Record<number, ExtendedServiceCode[]> = fromPairs(
      claims?.map(({ claimId }) => [claimId ?? 0, []]),
    );

    (bill?.serviceCodes ?? []).forEach((code) => {
      codesByClaimIds[code.serviceCodeDetails?.primaryClaimId ?? 0]?.push(code);
    });

    return {
      patient: patient as PatientDTO,
      claims: (claims ?? []).map((claim) => ({
        ...claim,
        status: isOutOfPocket ? 'Out-of-pocket' : claim?.status,
      })),
      keyedSecondaryClaims,
      codesByClaimIds,
      bill,
      visitDateTime,
      encounterId,
      patientId: patient?.patientId,
      isFinalized,
      reasonForVisit,
      patientResponsibility,
      patientBalance,
      isOutOfPocket,
    };
  }) ?? [];
}

export function normalizeClaimForm({
  claim,
  visitNote,
  serviceCodeDefaults,
}: ClaimBundleDTO): Claim {
  return {
    visitNote,
    transmissions: [...(claim?.transmissions ?? [])].reverse(),
    assessmentCodes: visitNote?.bill?.assessmentCodes ?? [],
    billingCodes: normalizeBillingCodes(
      visitNote?.bill?.serviceCodes ?? [],
      serviceCodeDefaults as ServiceCodeDetailsDTO,
    ),
    ...pick(claim, ['claimId', 'patientId', 'encounterId', 'organizationId', 'externalId', 'totalAmountPaid']),
    dates: (
      claim?.details?.dates ?? []
    ).map(({ qualifier, date }) => ({
      item: { qualifier: qualifier ?? '' },
      date: dayjs(date),
    })),
    priorAuthCode: claim?.details?.priorAuthCode ?? '',
    ...pick(claim?.details ?? {}, [
      'autoAccident',
      'otherAccident',
      'originalReferenceNumber',
      'additionalInformation',
    ]),
    ...(claim?.details?.employment ?? {}),
    ...omit(claim?.context ?? {}, ['insuranceDetails', 'assignment', 'insurancePolicy', 'insuranceType']),
    ...(claim?.context?.insuranceDetails ?? {}),
    ...pick(claim?.notes ?? {}, [
      'flag',
      'notesText',
    ]),
    billingProvider: { id: claim?.context?.billingProviderUserId ?? 0, name: '' },
    renderingProvider: { id: claim?.context?.renderingProviderUserId ?? 0, name: '' },
    serviceFacility: { addressId: claim?.context?.serviceFacilityAddressId ?? 0 },
    insurance: { insuranceId: claim?.context?.insuranceDetails?.insuranceId ?? 0 },
    assignment: { code: claim?.context?.insuranceDetails?.assignment ?? '' },
    insurancePolicy: { code: claim?.context?.insuranceDetails?.insurancePolicy ?? '' },
    insuranceType: { code: claim?.context?.insuranceDetails?.insuranceType },
    resubmissionCode: { code: claim?.details?.resubmissionCode },
    referencedPrimaryClaimId: claim?.referencedPrimaryClaimId,
  };
}

export function serializeClaimForm({
  serviceFacility,
  referringProvider,
  assignment,
  insurance,
  insuranceType,
  insurancePolicy,
  billingProvider,
  renderingProvider,
  additionalInformation,
  dates,
  priorAuthCode,
  isRelatedToEmployment,
  autoAccident,
  otherAccident,
  resubmissionCode,
  originalReferenceNumber,
  assessmentCodes,
  billingCodes,
  flag,
  notesText,
}: Claim, originalData?: ClaimBundleDTO): ClaimBundleDTO {
  return {
    visitNote: {
      ...(originalData?.visitNote ?? {}),
      bill: {
        ...(originalData?.visitNote?.bill ?? {}),
        assessmentCodes,
        serviceCodes: formatBillingCodes(billingCodes ?? [], assessmentCodes ?? []),
      },
    },
    claim: {
      ...(originalData?.claim ?? {}),
      context: {
        referringProvider: values(referringProvider).every(isEmptyString) ? undefined : {
          ...referringProvider,
          ...setDefaultValueIf(referringProvider ?? {}, null, isEmptyString),
        },
        billingProviderUserId: billingProvider?.id === 0 ? undefined : billingProvider?.id,
        renderingProviderUserId: renderingProvider?.id,
        insuranceDetails: {
          assignment: isEmptyString(assignment?.code) ? undefined : assignment?.code,
          insuranceId: insurance?.insuranceId === 0 ? null : insurance?.insuranceId,
          insuranceType: isEmptyString(insuranceType?.code) ? undefined : insuranceType?.code,
          insurancePolicy: isEmptyString(insurancePolicy?.code) ? undefined : insurancePolicy?.code,
        },
        serviceFacilityAddressId: serviceFacility?.addressId,
      },
      details: {
        employment: {
          isRelatedToEmployment: isRelatedToEmployment ?? false,
        },
        autoAccident: {
          isAutoAccident: autoAccident?.isAutoAccident ?? false,
          autoAccidentState: autoAccident?.autoAccidentState,
        },
        otherAccident: {
          isOtherAccident: otherAccident?.isOtherAccident ?? false,
        },
        additionalInformation,
        dates: dates?.map(({ item, date }) => ({
          qualifier: item.qualifier,
          date: formatDateOnly(date),
        })),
        priorAuthCode,
        resubmissionCode: isEmptyString(resubmissionCode?.code)
          ? undefined : resubmissionCode?.code,
        originalReferenceNumber,
      },
      notes: {
        flag,
        notesText,
      },
    },
  };
}

export function normalizeClaimSplits(claim: SplitClaimsDTO): ClaimSplit[] {
  return (claim?.splitClaims ?? []).map((claimSplit) => ({
    ...claimSplit,
    claimId: (claimSplit?.claimId ?? 0).toString(),
    serviceCodes: (claimSplit.serviceCodes ?? []).map((serviceCode) => ({
      ...serviceCode,
      serviceCodeDetails: {
        ...(serviceCode?.serviceCodeDetails ?? {}),
        primaryClaimId: (claimSplit?.claimId ?? 0).toString(),
      },
    })),
  }));
}

function serializeServiceCodesForSplit(
  serviceCodes: VisitNoteBillingCodeDTO[],
  claimId: number,
): VisitNoteBillingCodeDTO[] {
  return (serviceCodes ?? []).map((service) => ({
    ...service,
    serviceCodeDetails: {
      ...service?.serviceCodeDetails,
      primaryClaimId: claimId,
    },
  }));
}

export function serializeClaimSplits(splits: ClaimSplit[]): SplitClaimsDTO {
  return {
    splitClaims: splits.map((claimSplit) => {
      const claimId = /^new-/ig.test(claimSplit.claimId) ? 0 : Number(claimSplit.claimId);
      return {
        ...claimSplit,
        insuranceId: claimSplit?.insuranceId === 0 ? null : claimSplit?.insuranceId,
        externalId: claimSplit?.externalId ?? '',
        status: claimSplit?.status ?? 'Draft',
        claimId,
        serviceCodes: serializeServiceCodesForSplit(claimSplit?.serviceCodes ?? [], claimId),
      };
    }),
  };
}

export type CollectPaymentData = Pick<PatientAsPayerPaymentRequestDTO,
'patientId' |
'transactionId' |
'comment'> &{
  paymentMethod: AppointmentPaymentMethod | null;
  cardType?: TerminalCardType | null;
  isCopay: boolean;
  billInfo?: VisitNoteBillInfo[];
  encounterId?: number | null;
  amount?: number | null;
  previousPayment?: Payment;
};

export function serializePaymentByEncounter(billInfo: VisitNoteBillInfo[]) {
  return billInfo.map(({
    encounterId,
    balance,
  }) => ({
    encounterId,
    paymentAmount: balance,
  }));
}

export function serializeCollectPaymentData({ // @TODO: very messy and need a fix
  patientId,
  paymentMethod,
  transactionId,
  isCopay,
  encounterId,
  amount,
  billInfo = [],
  cardType,
  comment,
  previousPayment = {},
}: CollectPaymentData): PatientAsPayerPaymentRequestDTO {
  const prevPaymentByEncounter = keyBy(
    previousPayment?.patientPaymentByEncounters,
    'encounterId',
  );

  return {
    patientId,
    paymentAmount: amount ?? 0,
    ...(isCopay ? {
      paymentByEncounters: [{
        ...prevPaymentByEncounter[encounterId ?? 0],
        encounterId: encounterId ?? 0,
        paymentAmount: amount ?? 0,
      }],
      paymentType: PatientPaymentTypes.Copay,
      transactionId,
    } : {
      paymentByEncounters: serializePaymentByEncounter(billInfo),
      writeOffs: billInfo
        .filter(({ writeOff }) => !isNil(writeOff))
        .map(({ writeOff, encounterId }) => ({
          ...writeOff,
          encounterId: encounterId ?? 0,
        })),
      transactionId,
      paymentType: PatientPaymentTypes.PatientBalance,
    }),
    isCardPresent: cardType?.isCardPresent,
    comment,
    isCash: /Cash$/i.test(paymentMethod?.name ?? ''),
    isCheck: /Check$/i.test(paymentMethod?.name ?? ''),
    isCreditTransfer: /creditTransferPayment$/i.test(paymentMethod?.discriminator ?? ''),
    isCard: !isNil(paymentMethod?.deviceId) || /Card$/i.test(paymentMethod?.name ?? ''),
    sendToTerminalDevice: !isNil(paymentMethod?.deviceId),
    deviceId: paymentMethod?.deviceId ?? null,
    paymentDate: formatDateTimeOffset(dayjs()),
    ...(isNil(previousPayment) ? {} : {
      patientPaymentId: previousPayment?.patientPaymentId,
    }),
  };
}

export function serializeEOBWithInsurancePaymentRequest(
  data: EOBWithInsurancePaymentRequestDTO,
): EOBWithInsurancePaymentRequestDTO {
  const { discriminator } = data?.insurancePayment?.paymentMethod ?? {};

  const isACHPayment = /^ach/i.test(discriminator ?? '');
  const achFieldValues = pick(data?.insurancePayment?.paymentMethod, achFields);

  const achInfo = isACHPayment ? {
    ...achFieldValues,
    ...getDefaultIf(achFieldValues, null, isEmptyString),
  } : {};
  return {
    ...data,
    ...(!isNil(data?.insurancePayment) && {
      insurancePayment: {
        ...data.insurancePayment,
        paymentMethod: {
          ...data.insurancePayment?.paymentMethod,
          ...achInfo,
        },
      },
    }),
  };
}

export const normalizePatientInvoice = (data: PatientStatementDTO): PatientInvoice => ({
  ...data,
  visitNotesForStatement: toPairs(data.billingData ?? {}).reduce((acc, [key, value]) => {
    const { billingCodes, customServiceCodes, encounterId, isOutOfPocket } = value;
    const allocation = withServiceCodesAllocation(
      [...(billingCodes ?? []), ...(customServiceCodes ?? [])],
      { encounterId: encounterId ?? 0 },
      isOutOfPocket,
      true,
    );

    return {
      ...acc,
      [key]: {
        ...value,
        patientBalance: data?.patientEncounterFinancial?.[key] ?? {},
        allocation,
      },
    };
  }, {}),
});

export function normalizeCPTPrice({
  chargeMasterId: id,
  billingCodeId: codeId,
  ...data
}: ChargeMasterDTO) {
  return {
    id,
    codeId,
    isCustom: false,
    ...data,
  };
}

export function normalizeCustomServicePrice({
  customServiceCodeId: codeId,
  customChargeMasterId: id,
  ...data
}: CustomChargeMasterDTO) {
  return {
    id,
    codeId,
    isCustom: true,
    ...data,
  };
}

export function serializePrice({
  id,
  codeId,
  isCustom,
  chargeAmount,
  billingCode,
  billableToInsurance,
  customServiceCode,
}: Price) {
  return {
    ...(isCustom
      ? {
        customChargeMasterId: id,
        customServiceCodeId: codeId ?? customServiceCode?.customServiceCodeId,
        customServiceCode,
      }
      : {
        chargeMasterId: id,
        billingCodeId: codeId ?? billingCode?.billingCodeId,
        billingCode,
        billableToInsurance,
      }
    ),
    chargeAmount,
  };
}

export function serializePatientStatementPayload(
  data: PatientStatementRequest,
): PatientStatementRequestDTO {
  const { phone, billInfo } = data;
  const payload = omit(data, 'billInfo');

  return {
    ...pickBy(payload, (value) => !isEmptyString(value)),
    ...(isEmptyString(phone)) ? {} : {
      phone: normalizePhoneNumber(phone ?? ''),
    },
    ...(isNil(billInfo) ? {} : {
      paymentByEncounters: serializePaymentByEncounter(data?.billInfo ?? []),
    }),

  };
}
