import pick from 'lodash/pick';
import values from 'lodash/values';
import {
  AddressDTO,
} from 'dtos';
import {
  isEmptyString,
  notEmptyString,
} from 'utils/misc';

export const addressFields = Object.freeze(['street', 'unit', 'state', 'zipCode', 'city']);
export const requiredAddressFields = Object.freeze(new Set(['street', 'state', 'zipCode', 'city']));

export const validateAddress = (
  address: Pick<AddressDTO, 'street' | 'unit' | 'city' | 'state' | 'zipCode'>,
) => (
  values(pick(address, addressFields)).every(isEmptyString)
    || values(pick(address, Array.from(requiredAddressFields))).every(notEmptyString)
);

export const validateZipCode = (value: string | null | undefined) => (
  isEmptyString(value) ? true : value?.length === 5
);

export const validateAddressInfo = (
  value?: Pick<AddressDTO, 'street' | 'unit' | 'city' | 'state' | 'zipCode'>,
) => validateAddress(value ?? {}) && validateZipCode(value?.zipCode);
