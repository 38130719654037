import React, {
  memo,
  ReactNode,
  useEffect,
  useMemo,
} from 'react';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import axios, {
  AxiosError,
  AxiosRequestConfig,
} from 'axios';
import isNil from 'lodash/isNil';
import {
  AlertVariant,
} from 'pages/Dashboard/components/Alert';
import {
  isEmptyString,
} from 'utils/misc';
import {
  getErrorMessage,
} from 'utils/errors';
import {
  useAuth,
  useNotify,
} from 'core/hooks';
import {
  ReactQueryDevtools,
} from 'react-query/devtools';
import isEmpty from 'lodash/isEmpty';

type Props = {
  children: ReactNode;
};

function ReactQueryProvider({ children }: Props) {
  const {
    logout,
    issueAccessToken,
    isAuthenticated,
  } = useAuth();
  const notify = useNotify();

  const useErrorBoundary = (_error: unknown) => (_error as AxiosError)?.response?.status === 410;

  const onError = (error: unknown) => {
    const axiosError = error as AxiosError;

    if (axiosError?.response?.status === 410) {
      return;
    }

    const message = getErrorMessage(error);

    if (!isEmptyString(message) && !isEmpty(message)) {
      notify({
        variant: AlertVariant.ERROR,
        message,
      });
    }
  };

  const retryFn = (failureCount: number, error: unknown) => {
    const { status } = (error as AxiosError)?.response ?? {};
    if (isAuthenticated && failureCount < 2 && status === 401) {
      issueAccessToken();
      return true;
    }

    // failure count is over 3
    if (status === 401) {
      logout();
    }

    return false;
  };

  const commonOptions = useMemo(() => ({
    retry: retryFn,
    onError,
    useErrorBoundary,
  }), [retryFn, onError, useErrorBoundary]);

  const queryClient = useMemo(() => new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: 10 * 60 * 1000,
        cacheTime: 0,
        ...commonOptions,
      },
      mutations: commonOptions,
    },
  }), [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
        const token = await issueAccessToken();
        return isNil(token)
          ? config
          : {
            ...config,
            headers: {
              ...(config.headers ?? {}),
              Authorization: `Bearer ${token}`,
            },
          };
      });
    }
  }, [isAuthenticated]);

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools
        position="top-left"
      />
    </QueryClientProvider>
  );
}

export default memo(ReactQueryProvider);
